export const ebonyClay = {
    50: { value: '#BBC3D5' },
    100: { value: '#9EA5B7' },
    200: { value: '#808899' },
    300: { value: '#636A7C' },
    400: { value: '#454D5E' },
    500: { value: '#282F40' },
    600: { value: '#202633' },
    700: { value: '#181C26' },
    800: { value: '#10131A' },
    900: { value: '#08090D' },
};

export const eachPallet = {
    50: { value: '#C5E1A5' },
    100: { value: '#C5E1A5' },
    200: { value: '#C5E1A5' },
    300: { value: '#C5E1A5' },
    400: { value: '#C5E1A5' },
    500: { value: '#C5E1A5' },
    600: { value: '#C5E1A5' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#C5E1A5' },
    900: { value: '#C5E1A5' },
    950: { value: '#C5E1A5' },
};

export const packPallet = {
    50: { value: '#E6ED9C' },
    100: { value: '#E6ED9C' },
    200: { value: '#E6ED9C' },
    300: { value: '#E6ED9C' },
    400: { value: '#E6ED9C' },
    500: { value: '#E6ED9C' },
    600: { value: '#E6ED9C' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#E6ED9C' },
    900: { value: '#E6ED9C' },
    950: { value: '#E6ED9C' },
};

export const casePallet = {
    50: { value: '#FFCC80' },
    100: { value: '#FFCC80' },
    200: { value: '#FFCC80' },
    300: { value: '#FFCC80' },
    400: { value: '#FFCC80' },
    500: { value: '#FFCC80' },
    600: { value: '#FFCC80' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#FFCC80' },
    900: { value: '#FFCC80' },
    950: { value: '#FFCC80' },
};

export const mixedModulePallet = {
    50: { value: '#B49DDB' },
    100: { value: '#B49DDB' },
    200: { value: '#B49DDB' },
    300: { value: '#B49DDB' },
    400: { value: '#B49DDB' },
    500: { value: '#B49DDB' },
    600: { value: '#B49DDB' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#B49DDB' },
    900: { value: '#B49DDB' },
    950: { value: '#B49DDB' },
};

export const palletPallet = {
    50: { value: '#CE94D8' },
    100: { value: '#CE94D8' },
    200: { value: '#CE94D8' },
    300: { value: '#CE94D8' },
    400: { value: '#CE94D8' },
    500: { value: '#CE94D8' },
    600: { value: '#CE94D8' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#CE94D8' },
    900: { value: '#CE94D8' },
    950: { value: '#CE94D8' },
};

export const shipperPallet = {
    50: { value: '#9EA7DA' },
    100: { value: '#9EA7DA' },
    200: { value: '#9EA7DA' },
    300: { value: '#9EA7DA' },
    400: { value: '#9EA7DA' },
    500: { value: '#9EA7DA' },
    600: { value: '#9EA7DA' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#9EA7DA' },
    900: { value: '#9EA7DA' },
    950: { value: '#9EA7DA' },
};

export const truckloadPallet = {
    50: { value: '#F48FB1' },
    100: { value: '#F48FB1' },
    200: { value: '#F48FB1' },
    300: { value: '#F48FB1' },
    400: { value: '#F48FB1' },
    500: { value: '#F48FB1' },
    600: { value: '#F48FB1' },
    700: { value: '#2D3748' }, // gray.700, for tag contrast
    800: { value: '#F48FB1' },
    900: { value: '#F48FB1' },
    950: { value: '#F48FB1' },
};

export const colorTokens = {
    'bg.canvas': {
        value: '{colors.gray.25}',
    },
    'bg.surface': {
        value: '{colors.white}',
    },
    'bg.subtle': {
        value: '{colors.gray.50}',
    },
    'bg.muted': {
        value: '{colors.gray.100}',
    },
    'fg.default': {
        value: '{colors.gray.900}',
    },
    'fg.emphasized': {
        value: '{colors.gray.700}',
    },
    'fg.muted': {
        value: '{colors.gray.600}',
    },
    'fg.subtle': {
        value: '{colors.gray.500}',
    },
    'fg.inverted': {
        value: '{colors.white}',
    },
    'border.default': {
        value: '{colors.gray.200}',
    },
    'border.emphasized': {
        value: '{colors.gray.300}',
    },
    'border.active': {
        value: '{colors.gray.400}',
    },
    'bg.accent.default': { value: '{colors.brand.600}' },
    'bg.accent.subtle': { value: '{colors.brand.500}' },
    'bg.accent.muted': { value: '{colors.brand.400}' },
    'fg.accent.subtle': { value: '{colors.brand.100}' },
    'fg.accent.muted': { value: '{colors.brand.50}' },
    'fg.accent.default': { value: '{colors.white}' },
    success: {
        solid: { value: '{colors.green.500}' },
        muted: { value: '{colors.green.300}' },
        subtle: { value: '{colors.green.200}' },
    },
    error: {
        value: '{colors.red.500}',
    },
    brand: {
        solid: { value: '{colors.brand.500}' },
        contrast: { value: 'white' },
        fg: { value: '{colors.brand.700}' },
        muted: { value: '{colors.brand.400}' },
        subtle: { value: '{colors.brand.50}' },
        emphasized: { value: '{colors.brand.400}' },
        focusRing: { value: '{colors.brand.900}' },
    },
    each: {
        solid: { value: '{colors.each.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.each.700}' },
        muted: { value: '{colors.each.400}' },
        subtle: { value: '{colors.each.50}' },
        emphasized: { value: '{colors.each.400}' },
        focusRing: { value: '{colors.each.500}' },
    },
    pack: {
        solid: { value: '{colors.pack.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.pack.700}' },
        muted: { value: '{colors.pack.400}' },
        subtle: { value: '{colors.pack.50}' },
        emphasized: { value: '{colors.pack.400}' },
        focusRing: { value: '{colors.pack.500}' },
    },
    case: {
        solid: { value: '{colors.case.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.case.700}' },
        muted: { value: '{colors.case.400}' },
        subtle: { value: '{colors.case.50}' },
        emphasized: { value: '{colors.case.400}' },
        focusRing: { value: '{colors.case.500}' },
    },
    pallet: {
        solid: { value: '{colors.pallet.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.pallet.700}' },
        muted: { value: '{colors.pallet.400}' },
        subtle: { value: '{colors.pallet.50}' },
        emphasized: { value: '{colors.pallet.400}' },
        focusRing: { value: '{colors.pallet.500}' },
    },
    shipper: {
        solid: { value: '{colors.shipper.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.shipper.700}' },
        muted: { value: '{colors.shipper.400}' },
        subtle: { value: '{colors.shipper.50}' },
        emphasized: { value: '{colors.shipper.400}' },
        focusRing: { value: '{colors.shipper.500}' },
    },
    truckload: {
        solid: { value: '{colors.truckload.500}' },
        contrast: { value: 'gray.700' },
        fg: { value: '{colors.truckload.700}' },
        muted: { value: '{colors.truckload.400}' },
        subtle: { value: '{colors.truckload.50}' },
        emphasized: { value: '{colors.truckload.400}' },
        focusRing: { value: '{colors.truckload.500}' },
    },
};
