import { defineSlotRecipe } from '@chakra-ui/react';

type Side = 'n' | 's' | 'e' | 'w' | 'ne' | 'nw' | 'se' | 'sw';
type BorderConfig = { side: Side | Side[]; size: number; color?: string; inset?: boolean };
type BoxShadowBorderType = (
    sides: BorderConfig[],
    getMakeSubStyle?: (border: BoxShadowBorderType) => object,
    _startingBoxShadows?: string[]
) => object;

const boxShadowBorder: BoxShadowBorderType = (sides, getMakeSubStyle, _startingBoxShadows = []) => {
    const makeBoxShadow = (sides: BorderConfig[]) => {
        return sides.flatMap(({ side: sides, size, inset, color = 'var(--chakra-colors-gray-300)' }) => {
            sides = sides instanceof Array ? sides : [sides];

            const insetFlip = inset ? -1 : 1;
            return sides.map((side) => {
                const x = (side.includes('w') ? -size : side.includes('e') ? size : 0) * insetFlip;
                const y = (side.includes('n') ? -size : side.includes('s') ? size : 0) * insetFlip;
                return `${inset ? 'inset ' : ''} ${x}px ${y}px ${color}`;
            });
        });
    };
    const boxShadow = [...makeBoxShadow(sides), ...(_startingBoxShadows ?? [])];

    const makeSubStyle: BoxShadowBorderType = (sides, makeSubStyle) => boxShadowBorder(sides, makeSubStyle, boxShadow);
    const subStyles = getMakeSubStyle?.(makeSubStyle);

    return { boxShadow: boxShadow.join(','), ...(subStyles ?? {}) };
};

const header = {
    bg: 'white',
    '& > tr': {
        zIndex: 2,
        '&:hover': {
            // disable row hover inside header because cells are colored instead
            bg: 'white !important',
        },
    },
};
const body = {};
const footer = {};
const row = {
    bg: 'white',
    _hover: {
        '& td': {
            bg: 'gray.900/05',
        },
    },
    '& td': {
        bg: 'white',
    },
    _even: {
        '& td': {
            bg: 'gray.50',
        },
    },
};
const columnHeader = {
    position: 'relative',
    bg: 'white',
    _hover: {
        bg: 'gray.900/05',
    },

    // user select messes with col resize and there probably isn't any use
    // for highlighting column headers.
    userSelect: 'none',

    ...boxShadowBorder([{ side: 's', size: 2, inset: true }]),
};

const cell = {
    position: 'relative',
};
const root = {
    // Borders come from the background. Cell spacing exposes that background
    borderCollapse: 'separate',
    borderSpacing: '1px',
    bg: 'gray.300',

    // Since the top of the table has an exposed background-border and it combines
    // with the top row border, lets cover it
    ...boxShadowBorder([{ side: 'n', size: 1, color: 'white', inset: true }]),

    '& th.resizing, & td.resizing': {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '-2px',
            bottom: '-2px',
            right: '-1px',
            width: '2px',
            bg: 'gray.900',
            zIndex: 1,
            ...boxShadowBorder([
                { side: ['sw', 'se', 'nw', 'ne'], size: 1, color: 'var(--chakra-colors-gray-900)', inset: true },
            ]),
        },
    },
};

export const tableSlotRecipe = defineSlotRecipe({
    slots: ['root', 'header', 'body', 'footer', 'row', 'columnHeader', 'cell'],
    base: {
        root: {},
        footer: {},
        row: {},
        columnHeader: {},
        cell: {},
    },
    variants: {
        variant: {
            bgBorder: {
                root,
                header,
                body,
                footer,
                row,
                columnHeader,
                cell,
            },
            line: {
                root: {
                    borderRadius: 0,
                    borderCollapse: 'collapse',
                    borderColor: 'gray.300',
                },
                row: {
                    borderColor: 'gray.300',
                },
                columnHeader: {
                    borderColor: 'gray.300',
                },
                cell: {
                    borderColor: 'gray.300',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'bgBorder',
    },
});
